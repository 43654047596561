import './Services.css';
import React from 'react'

export default function Services() {

  return (
		<div className="services-root">
			<div className="services-header">
				<h2 className="services-title">
					<span>Services</span> we offer to our clients
				</h2>
				<p>
					These are the different services we offer to our clients for their
					Business!
				</p>
			</div>
			<h2 className="dev-services-title">
				<i className="fab fa-connectdevelop"></i> Web Development Services
			</h2>
			<div className="divider"></div>
			<div className="services">
				<div className="service-box static-web">
					<h3>Static Website</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> Static Website made using
							latest tech
						</li>
						<li>
							<i className="fas fa-check"></i> Responsive web design
							customized for different screen sizes
						</li>
						<li>
							<i className="fas fa-check"></i> SEO Friendly
						</li>
					</ul>
				</div>
				<div className="service-box static-hosting-web">
					<h3>Static Website + Hosting</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> Help in connecting your site
							to your domain name
						</li>
						<li>
							<i className="fas fa-check"></i> Hosting your site on a
							free tier hosting or hosting provided by you
						</li>
						<li>
							<i className="fas fa-check"></i> Everything from previous
							Static Website package.
						</li>
					</ul>
				</div>
				<div className="service-box dynamic-web">
					<h3>Dynamic Website</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> Dynamic Website (such as
							e-commerce or management websites) made using ReactJS
						</li>
						<li>
							<i className="fas fa-check"></i> REST-API development!
						</li>
						<li>
							<i className="fas fa-check"></i> SSL Certificate Configuration
							<sup>*</sup>
						</li>
						<li>
							<i className="fas fa-check"></i> Everything from previous
							packages
						</li>
					</ul>
				</div>
			</div>
			<h2 className="dev-services-title">
				<i className="fas fa-mobile"></i> App Development Services
			</h2>
			<div className="divider"></div>
			<div className="services">
				<div className="service-box static-app">
					<h3>Basic App</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> A simple Android application
							made in Java
						</li>
						<li>
							<i className="fas fa-check"></i> Responsive app design
							customized for different screen sizes.
						</li>
						<li>
							<i className="fas fa-check"></i> Fast performance due to native
							development & not some fancy frameworks.
						</li>
					</ul>
				</div>
				<div className="service-box static-hosting-web">
					<h3>API Connected App + Playstore deploy</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> Connecting your app to your
							existing API.
						</li>
						<li>
							<i className="fas fa-check"></i> Hosting your app on playstore
							account provided by you.
						</li>
						<li>
							<i className="fas fa-check"></i> Everything from previous
							package.
						</li>
					</ul>
				</div>
				<div className="service-box dynamic-web">
					<h3>Dynamic App with customized API</h3>
					<ul>
						<li>
							<i className="fas fa-check"></i> A complete REST-API
							development solution with hosting (server provided by you)!
						</li>
						<li>
							<i className="fas fa-check"></i> App developed in Java & connected with API!
						</li>
						<li>
							<i className="fas fa-check"></i> Everything from previous
							packages
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
