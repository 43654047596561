import './App.css';
import Contact from './components/Contact';
import Header from './components/Header';
import Intro from './components/Intro';
import Philosophy from './components/Philosophy';
import Services from './components/Services';
import SubHeader from './components/SubHeader';

function App() {
  return (
		<div className="App">
			<header className="App-header">
				<Header />
			</header>
			<main>
				<Intro />
				<SubHeader />
				<Philosophy />
				<div className="divider"></div>
				<Services />
				<Contact />
			</main>
			<footer>
				<div>
					<h2>Darbar Technologies</h2>
					<p>
						&copy;{" "}
						{new Date().getFullYear()} Darbar Technologies - All Rights Reserved
					</p>
				</div>
			</footer>
		</div>
	);
}

export default App;
