import './SubHeader.css';
import React from 'react';

export default function SubHeader() {
  return (
		<div className="sub-header-root">
			<div className="sub-header-text-content-container">
				<h2 className="sub-header-title">Our Philosophy</h2>
				<h3 className="sub-header-subtitle">Grow your business with us!</h3>
				<p className="sub-header-desc">
					We at <span className="underline-span">Darbar Technologies</span> are
					passionate about everything that has to do with Digital presence, be
					it a Website or an App. We enjoy working with enthusiastic people who
					want to grow their businesses and make/improve their online presence.
				</p>
			</div>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				data-name="Layer 1"
				width="342.61867"
				height="426.75102"
				viewBox="0 0 342.61867 426.75102"
				className="growth-svg"
			>
				<path
					d="M451.00586,556.00956l-.08125-29.20943L436.47873,629.17658c10.6892.29756,21.37839.59512,34.05386.46394C463.946,599.12659,451.04512,570.12352,451.00586,556.00956Z"
					transform="translate(-428.87421 -215.77858)"
					fill="#ffa500"
				/>
				<path
					d="M577.02216,476.9715,539.51692,617.36175c22.16325.62745,44.32649,1.25489,68.25368,1.82356C593.84164,571.93551,579.91268,524.6857,577.02216,476.9715Z"
					transform="translate(-428.87421 -215.77858)"
					fill="#ffa500"
				/>
				<path
					d="M665.93368,417.08643c.7182,70.16017,1.43639,140.32034.078,210.4853,17.78437-.32482,35.56875-.64964,59.0699-1.26883C709.20363,556.763,693.32564,487.2231,665.93368,417.08643Z"
					transform="translate(-428.87421 -215.77858)"
					fill="#ffa500"
				/>
				<path
					d="M487.07584,484.83047a115.88478,115.88478,0,0,0-13.54972-2.8365c1.08077.36646,2.16366.725,3.24091,1.101,11.89062,4.93217,26.87387,8.96979,33.45619,21.58472,3.04345,7.778,2.37236,16.42382,1.74624,24.58777a282.59893,282.59893,0,0,1-5.8529,33.43932c-5.49662,25.333-16.076,54.33416-26.89846,61.69965-3.021,3.30468-13.48507,2.39607-18.28882,1.55089-14.088-2.90818-18.11876-5.13385-18.0766-14.24084,3.85368-45.96062,3.3161-92.10781,4.80937-138.17419-6.26258,35.89407-10.091,72.12545-14.69655,108.25124-.77088,5.81619-.40546,3.07174-1.104,8.25021-.974,12.187-6.6041,25.9809.70623,37.13472,6.3455,9.44376,18.06816,12.87493,28.786,14.435,16.00078,3.05663,27.75437-1.43248,36.96272-15.00873,15.84408-26.52427,18.94516-57.01606,21.85018-84.83631C521.93324,511.23686,520.92133,492.5828,487.07584,484.83047Z"
					transform="translate(-428.87421 -215.77858)"
				/>
				<path
					d="M622.1486,431.09468a15.66907,15.66907,0,0,0-12.43521-11.907c-11.7557-3.28887-24.11572-1.91436-36.12931-1.19461,10.59551,1.16756,19.92193,1.32145,30.08035,4.40549,10.97568,3.038,11.66856,6.6619,12.4816,14.95778.86714,12.64111.64228,25.33791.3387,37.9973-.78071,38.62727-10.40015,118.39948-28.37205,137.97743-3.84806,2.36287-1.203,1.28368-5.76436,2.30911-.13211.004-.23892.00949-.39633.01177-12.66079-.28618-21.05962-2.24464-29.51958-6.86463-.253-.19922-.46028-.35821-.63666-.49a18.43939,18.43939,0,0,1-1.84814-1.68054c-.0253-.03232-.03654-.0455-.05973-.07589-.19184-.29-.37455-.58307-.53828-.88946a18.38234,18.38234,0,0,1-.52914-1.88151,38.07079,38.07079,0,0,1-.20941-3.99247c2.35409-60.40612-.17357-120.856,1.78349-181.2702-8.52462,48.74653-12.3523,98.08615-16.11041,147.38079-.76947,9.97959-1.66684,19.95021-2.30771,29.93928-.63315,7.93434-.104,16.69155,5.66106,22.76124,10.62643,11.47689,34.87988,15.88256,49.90389,13.78021,13.64459-2.66363,21.00341-15.47709,25.15433-27.65283a188.51487,188.51487,0,0,0,9.95253-35.97084C630.00354,523.39837,631.27193,476.24472,622.1486,431.09468Z"
					transform="translate(-428.87421 -215.77858)"
				/>
				<path
					d="M769.69664,435.70764c-1.75046-18.88841-1.55651-38.8258-9.20555-56.47919-10.63627-22.8048-39.49882-24.91-61.58229-27.07168,15.641,4.3888,29.55262,7.68171,42.50011,16.63885,14.00157,10.72077,12.83014,24.19442,13.81535,39.80151.799,24.34147-.12228,48.69119-.95569,73.01913-1.72868,50.3726-1.664,124.60128-15.06407,137.68334-4.36737,1.2879-23.47344,2.28066-35.6866,3.18874-12.45278.45044-22.62175,1.93721-30.06138-.37419-.62822-9.64158.37806-56.79893.72028-79.70686.91564-67.60155-2.22479-135.20434.96342-202.78324-11.76834,63.09752-16.0064,127.1574-18.33238,191.2092-.81937,20.68367-1.07375,41.38438-1.36819,62.08087.17849,11.45317-1.07164,23.02686.41952,34.40924,4.31045,15.79471,24.38346,14.30619,37.435,13.965q19.06813-1.03773,38.10815-2.55172c6.07215-.65476,12.68187-.64791,17.90654-4.208,8.11072-6.1447,11.65943-16.73565,14.62628-26.11968,5.29354-24.46725,5.90631-49.66445,6.90486-74.59619C771.95516,501.12323,771.68462,468.35852,769.69664,435.70764Z"
					transform="translate(-428.87421 -215.77858)"
				/>
				<path
					d="M661.58277,233.20724c-9.93145-15.30949-29.09093-17.84576-45.92724-17.37793-19.30072.0896-38.52064,2.74322-57.39271,6.67086-8.48386,2.28048-17.85946,3.91517-24.93438,9.37578-4.5318,4.477-3.236,12.2576,2.144,15.45583,3.07156,1.67439,6.61042,1.51663,10.01015,1.54456,21.04346-.03531,42.08621-.44113,63.129-.61751,5.95058-.059,10.33551.00317,13.89546.30129-4.9731,5.25384-10.325,10.15-15.589,15.10816C549.51474,314.49994,494.22173,367.69974,443.87583,425.604q67.22227-55.929,134.36583-111.95262C596.0674,298.671,613.64507,283.3808,630.62684,267.447c5.46852-5.46957,12.05435-10.56688,14.85255-18.003,1.15175-4.06871.87066-7.38517-.34362-10.09588.10962.07765.21081.14265.32184.22258,9.5295,6.29315,12.40008,33.61957,8.28358,62.29309-1.969,13.65143-8.97505,42.71478-14.66422,61.05666-1.45883,4.80076-3.0034,9.57693-4.67234,14.30742a392.27121,392.27121,0,0,0,24.95124-56.96845C667.42865,296.72094,676.45922,255.03317,661.58277,233.20724Z"
					transform="translate(-428.87421 -215.77858)"
				/>
				<path
					d="M565.57205,347.37041l80.177-74.65575C651.87468,314.56057,610.23771,356.13754,565.57205,347.37041Z"
					transform="translate(-428.87421 -215.77858)"
					fill="#ffa500"
				/>
			</svg>
		</div>
	);
}
