import "./Contact.css";
import React, { useEffect, useRef, useState } from "react";
import { useFetch } from "../hooks/useFetch";

export default function Contact() {
	const { postData, data, error } = useFetch(
		"https://assets.cashmen.in/darbar-tech-contact.php",
		"POST"
	);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const btn = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();
		postData({
			name,
			email,
			phone,
			message,
		});
	};

	useEffect(() => {
		if (data && data.result == "success") {
			// btn.current.classList.toggle('visible');
            document.querySelector('.contact-form .btn').classList.toggle('invisible');
            document.querySelector('.contact-form p').classList.toggle('invisible');
		}
	}, [data, error]);

	return (
		<div className="contact-root" id="contact">
			<div className="contact-desc">
				<h2 className="contact-title">Contact us</h2>
				<p className="contact-desc">
					Have a project in mind? Send us your details & we will get back to you
					soon and we will see what we can do for you & your business.
				</p>
			</div>
			<form className="contact-form" onSubmit={handleSubmit}>
				<label>
					<span>Name</span>
					<input
						type="text"
						onChange={(e) => setName(e.target.value)}
						value={name}
						required
					/>
				</label>
				<label>
					<span>E-mail</span>
					<input
						type="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
					/>
				</label>
				<label>
					<span>Phone</span>
					<input
						type="tel"
						onChange={(e) => setPhone(e.target.value)}
						value={phone}
						required
					/>
				</label>
				<label>
					<span>Message (your requirements)</span>
					<textarea
						placeholder="Please provide your project details and requirements!"
						onChange={(e) => setMessage(e.target.value)}
						value={message}
						required
					/>
				</label>
				<button className="btn" ref={btn}>
					Submit
				</button>
                <p className="invisible">Details Submitted Successfully!</p>
			</form>
		</div>
	);
}
