import React from 'react'
import './Intro.css'

export default function Intro() {
  return (
    <div className="intro-root">
        <h2><span className="intro-span">Let's</span><span className="intro-span">Build</span><span className="intro-span">Something</span><span className="intro-span">Great</span></h2>
        <div className="intro-text">
            <h1>Darbar <span className="tech-span">Technologies</span></h1>
            <h3>The digital agency designed to accelerate business growth and online presence.</h3>
        </div>
        <a className="contact-us-button" href="#contact">
            Contact Us
        </a>
    </div>
  )
}
