import './Philosophy.css';
import React from 'react'

export default function Philosophy() {
  return (
		<div className="philosophy-root">
			<div className="philosophy-desc-container">
				<h2 className="philosophy-header">
					We're not some drag & drop developers!
				</h2>
				<p className="philosophy-desc">
					At, Darbar Technologies we give priority to build software with in
					depth knowledge of inner workings rather than just using some fancy
					darg & drop utilites to create website & aplications quickly without
					understanding of inner workings. <br /> <br />
					Knowing how the software works behind the scenes helps us solve issues
					and/or add new features quickly and seamlessly. Plus that, it gives us
					flexibility to optimise the software for best performance.
				</p>
			</div>
			<div className="philosophy-boxes-container">
				<div className="philosophy-box">
					<span>
						<i className="fas fa-clock"></i>
					</span>{" "}
					Built as quickly as possible
				</div>
				<div className="philosophy-box">
					<span>
						<i className="fas fa-stream"></i>
					</span>{" "}
					Built in small milestones.
				</div>
				<div className="philosophy-box">
					<span>
						<i className="fas fa-code"></i>
					</span>{" "}
					Built with solid foundations, not some fancy drag & drop utilites.
				</div>
				<div className="philosophy-box">
					<span>
						<i className="fas fa-chart-line"></i>
					</span>{" "}
					Built with growth of your business in mind!
				</div>
			</div>
		</div>
	);
}
